import { keyframes, createGlobalStyle } from "styled-components";

export const easeInAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const easeOutAnimation = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

const neueHaasGroteskDisplayUrlNormalWoff2 =
  "https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3";
const neueHaasGroteskDisplayUrlNormalWoff =
  "https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3";
const neueHaasGroteskDisplayUrlNormalOpenType =
  "https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3";

const neueHaasGroteskDisplayUrlBoldWoff2 =
  "https://use.typekit.net/af/153042/00000000000000007735bb62/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3";
const neueHaasGroteskDisplayUrlBoldWoff =
  "https://use.typekit.net/af/153042/00000000000000007735bb62/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3";
const neueHaasGroteskDisplayUrlBoldOpenType =
  "https://use.typekit.net/af/153042/00000000000000007735bb62/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "neue-haas-grotesk-display";
    src:url(${neueHaasGroteskDisplayUrlNormalWoff2}) format("woff2"),
        url(${neueHaasGroteskDisplayUrlNormalWoff}) format("woff"),
        url(${neueHaasGroteskDisplayUrlNormalOpenType}) format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:400;
  }
  @font-face {
    font-family: "neue-haas-grotesk-display";
    src:url(${neueHaasGroteskDisplayUrlBoldWoff2}) format("woff2"),
        url(${neueHaasGroteskDisplayUrlBoldWoff}) format("woff"),
        url(${neueHaasGroteskDisplayUrlBoldOpenType}) format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:600;
  }

  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 31px;
    padding: 0;
    border-radius: 30px;
    background-color: #000000;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border: 2px solid white;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  }

  .react-toggle--checked .react-toggle-track {
    position: relative;
  }


  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 11px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
    position: relative;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #FFFFFF;
    outline: none;

    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 23px;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
  }
`;
