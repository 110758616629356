import React, { useState } from "react";

import SEO from "../seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  Description,
  SmallText,
  ScrollWrapper,
  StemsPriceContainer,
  BaseText,
  Stack,
  Inline,
} from "../../StyledComponents/mixdown.styles";
import { NextButton } from "../NextButton";
import { BackButton } from "../BackButton";
import Toggle from "react-toggle";
import { productionMap } from "../../pages/mixdown/costs";

const MixdownCostsMobile = ({ data }) => {
  const [typeOfProduction, setTypeOfProduction] = useState(true);

  const {
    titleLeftCosts,
    costsDescription,
    titleRightCosts,
    explanation,
    stemPrices,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const handleSwitch = e => {
    setTypeOfProduction(!typeOfProduction);
  };

  return (
    <>
      <SEO title="SPANDAU20 Mixdown Costs" />
      {/* <FixedHeaderButtons
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <BackButton />
        <NextButton url={"/mixdown/order"}>Get a quote</NextButton>
      </FixedHeaderButtons>
      <MixdownWrapper gap={"48px"} style={{ marginTop: 80 }}> */}
      <MixdownWrapper gap={"48px"}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton />
          <NextButton url={"/mixdown/order"}>Get a quote</NextButton>
        </div>
        <Stack gap={"48px"}>
          <Stack gap={"20px"}>
            <MixdownTitle>{titleRightCosts}</MixdownTitle>
            <Description>{explanation}</Description>
          </Stack>
          <Stack gap={"20px"}>
            <MixdownTitle>{titleLeftCosts}</MixdownTitle>
            <SmallText>{costsDescription}</SmallText>
            <Inline
              gap={"10px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <div style={{ textAlign: "right" }}>
                <SmallText>Band / </SmallText>
                <SmallText>unplugged</SmallText>
              </div>
              <Toggle
                onChange={handleSwitch}
                checked={typeOfProduction}
                icons={false}
              />
              <div>
                <SmallText>Electronic</SmallText>
                <SmallText>production</SmallText>
              </div>
            </Inline>
            <LeftWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              ></div>
              <ScrollWrapper>
                <StemsPriceContainer>
                  <SmallText>STEMS</SmallText>
                  <SmallText>PRICE</SmallText>
                </StemsPriceContainer>
                {stemPrices.map(stemPrice => (
                  <StemsPriceContainer key={stemPrice.stems}>
                    <BaseText>{stemPrice.stems}</BaseText>
                    <BaseText>
                      {stemPrice.price *
                        productionMap[typeOfProduction].priceMultiplier}{" "}
                      €
                    </BaseText>
                  </StemsPriceContainer>
                ))}
              </ScrollWrapper>
            </LeftWrapper>
          </Stack>
        </Stack>
      </MixdownWrapper>
    </>
  );
};

export default MixdownCostsMobile;
