import styled from "styled-components";
import { mediaQueries, fontSizes, lineHeights } from "../styles/variables";
import { easeInAnimation } from "../styles/GlobalStyles";
import ScrollArrow from "../images/arrow_icon.svg";

import { Title } from "./Releases.styles";

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  ${({ gap }) => (gap ? `gap: ${gap}` : "0px")};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : "")};
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent}` : ""};
`;

export const Inline = styled.div`
  display: flex;
  ${({ gap }) => (gap ? `gap: ${gap}` : "0px")};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : "")};
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent}` : ""};
`;

export const MixdownWrapper = styled(Stack)`
  margin-top: 6vh;
  padding-top: 5vh;
  overflow: hidden;
  margin-bottom: 8px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -webkit-animation-name: ${easeInAnimation};
  -webkit-animation-duration: 0.5s;

  ${mediaQueries.medium} {
    width: 100%;
    flex-direction: column;
    overflow: scroll;
  }
  ${mediaQueries.small} {
    margin-top: 0px;
    padding-top: 0vh;

    -webkit-animation-name: ${easeInAnimation};
    -webkit-animation-duration: 2s;
  }
`;

export const MixdownWrapperWeb = styled(Inline)`
  margin-top: 6vh;
  padding-top: 5vh;
  overflow: hidden;
  margin-bottom: 8px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -webkit-animation-name: ${easeInAnimation};
  -webkit-animation-duration: 0.5s;

  ${mediaQueries.medium} {
    width: 100%;
    flex-direction: column;
    overflow: scroll;

    -webkit-animation-name: ${easeInAnimation};
    -webkit-animation-duration: 2s;
  }
  ${mediaQueries.small} {
    margin-top: 0px;
    padding-top: 0vh;
    overflow: scroll;

    -webkit-animation-name: ${easeInAnimation};
    -webkit-animation-duration: 2s;
  }
`;

export const LeftWrapper = styled(Stack)`
  max-width: 450px;

  ${mediaQueries.medium} {
    max-width: 100%;
  }

  ${mediaQueries.small} {
    max-width: 100%;
  }
`;

export const ScrollWrapper = styled(Stack)`
  max-width: 450px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  ${mediaQueries.medium} {
    max-width: 100%;
  }

  ${mediaQueries.small} {
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  font-family: "neue-haas-grotesk-display";
  font-size: ${fontSizes.xBig}px;
  line-height: ${fontSizes.large}px;
  color: white;
  padding: 0;
  align-self: flex-end;

  ${mediaQueries.small} {
    font-size: ${fontSizes.xBig}px;
    line-height: ${fontSizes.large}px;
  }
  width: fit-content;
  position: relative;

  & > button {
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 300;

    &:nth-child(1) {
      padding-right: 20px;

      ${mediaQueries.small} {
        padding: 0;
      }
    }
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  margin-left: 17.5vw;
  height: 100%;

  margin-right: 50px;

  justify-content: space-between;

  ${mediaQueries.medium} {
    margin-left: 5vw;
  }
`;

export const MixdownTitle = styled(Title)`
  margin-bottom: 32px;

  ${mediaQueries.small} {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
`;

export const EngineerContainer = styled.div`
  display: flex;
  max-width: 40vw;
  margin-bottom: 50px;
`;

export const EngineerName = styled.p`
  font-family: "neue-haas-grotesk-text";
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const SmallText = styled.p`
  font-family: "neue-haas-grotesk-text";
  font-size: 10px;
  line-height: 12px;
`;

export const EngineerBio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 250px;
  margin-right: 45px;
`;

export const EngineerPhoto = styled.img`
  margin-top: 9px;

  max-width: 187px;

  ${mediaQueries.medium} {
    max-width: 100%;
  }

  ${mediaQueries.small} {
    max-width: 100%;

    width: 100%;
    height: auto;
  }
`;

export const Description = styled.p`
  font-family: "neue-haas-grotesk-text";
  padding-right: 20px;
  font-size: 16px;
  ${mediaQueries.small} {
    margin-top: 0px;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const StemsPriceHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-top: 24px;

  ${mediaQueries.small} {
    margin-top: 0px;
  }
`;

export const StemsPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  ${mediaQueries.small} {
    margin-top: 0px;
  }
`;

export const StemRow = styled.div``;

export const BaseText = styled.p`
  font-family: "neue-haas-grotesk-text";
  font-size: ${fontSizes.base}px;
  line-height: ${lineHeights.base}px;
`;

export const Container = styled(Inline)`
  display: flex;
  gap: 10px;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  justify-content: space-between;
`;

export const ArrowImage = styled(ScrollArrow)`
  position: absolute;
  bottom: 40px;
  left: 25px;

  animation: blink-animation 2s steps(150, start) infinite;
  -webkit-animation: blink-animation 2s steps(150, start) infinite;

  @keyframes blink-animation {
    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      opacity: 0;
    }
  }
`;

export const OrderLeftWrapper = styled(LeftWrapper)`
  min-width: 520px;

  ${mediaQueries.desktop} {
    min-width: 420px;
  }
  > * {
    max-width: 450px;
  }
`;

export const OrderScrollWrapper = styled(ScrollWrapper)`
  max-width: 520px;
  height: 100%;
  > * {
    max-width: 450px;
  }
`;

export const FixedHeaderButtons = styled(Inline)`
  width: 100%;
  position: fixed;
  background-color: black;
  padding-bottom: 15px;
  padding-right: 40px;
  z-index: 2;
`;
