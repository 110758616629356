import React, { useState, createContext, useCallback } from "react";
import { useWindowSize } from "../lib/useWindowSize";
import { screenSizes } from "../styles/variables";
import { productionMap } from "../pages/mixdown/costs";

export const FormContext = createContext();

export const FormContextProvider = ({ children }) => {
  const [bandOrElectronic, setBandOrElectronic] = useState(false);
  const [restoration, setRestoration] = useState(false);
  const [masterEffect, setMasterEffect] = useState(false);
  const [bpm, setBpm] = useState("");
  const [trackQuantity, setTrackQuantity] = useState("");
  const [stemsPerSong, setStemsPerSong] = useState("");
  const [listeningLink, setListeningLink] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [firstTrack, setFirstTrack] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [instagram, setInstagram] = useState("");
  const [streetAndHouseNumber, setStreetAndHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [isSubmissionError, setIsSubmissionError] = useState(false);
  const [additionalRequest, setAdditionalRequest] = useState("");

  const finalFormData = {
    full_name: fullName,
    email_address: email,
    phone_number: phoneNumber,
    instagram,
    address: streetAndHouseNumber,
    city,
    zip,
    country,
    band_or_electronic: productionMap[bandOrElectronic].title,
    restoration: restoration ? "yes" : "no",
    master_effect: masterEffect ? "yes" : "no",
    bpm,
    track_quantity: trackQuantity,
    stems_per_song: stemsPerSong,
    listening_link: listeningLink,
    project_link: projectLink,
    first_track: firstTrack,
    additional_request: additionalRequest,
  };

  return (
    <FormContext.Provider
      value={{
        bandOrElectronic,
        setBandOrElectronic,
        restoration,
        setRestoration,
        masterEffect,
        setMasterEffect,
        bpm,
        setBpm,
        trackQuantity,
        setTrackQuantity,
        stemsPerSong,
        setStemsPerSong,
        listeningLink,
        setListeningLink,
        projectLink,
        setProjectLink,
        firstTrack,
        setFirstTrack,
        fullName,
        setFullName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        instagram,
        setInstagram,
        streetAndHouseNumber,
        setStreetAndHouseNumber,
        city,
        setCity,
        zip,
        setZip,
        country,
        setCountry,
        isSubmissionError,
        setIsSubmissionError,
        additionalRequest,
        setAdditionalRequest,
        finalFormData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
