import { Link } from "gatsby";
import React, { useRef } from "react";
import styled from "styled-components";
import { mediaQueries, fontSizes } from "../styles/variables";

export const ButtonWrapper = styled.div`
  font-family: "neue-haas-grotesk-display";
  font-size: ${fontSizes.xBig}px;
  line-height: ${fontSizes.xBig}px;
  color: white;
  padding: 0;
  align-self: flex-end;

  ${mediaQueries.small} {
    font-size: ${fontSizes.xBig}px;
    line-height: ${fontSizes.large}px;
  }
  width: fit-content;
  position: relative;

  & > a {
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 300;
    text-decoration: none;
    text-transform: uppercase;

    &:nth-child(1) {
      padding-right: 20px;

      ${mediaQueries.small} {
        padding: 0;
      }
    }

    &:active {
      color: white;
    }
  }
`;

export const NextButton = ({ url, children, linkStyle }) => {
  const nextRef = useRef(null);

  return (
    <ButtonWrapper>
      <Link
        ref={nextRef}
        name="right"
        to={url}
        onMouseEnter={() => nextRef.current.classList.add("next-hover")}
        onMouseLeave={() => nextRef.current.classList.remove("next-hover")}
        style={linkStyle}
      >
        {children ?? "NEXT"}
      </Link>
    </ButtonWrapper>
  );
};
