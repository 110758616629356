import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Header from "./header";
import Menu from "./Menu";
import Sidebar from "./Sidebar";
import { useWindowSize } from "../lib/useWindowSize";
import { mediaQueries, screenSizes } from "../styles/variables";
import { MenuContext } from "../context/menuContext";

import "./layout.css";
import { FormContext } from "../context/formContext";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ isError }) => (isError ? "#D82A2A" : "black")};
  height: 100%;
`;

const StyledMain = styled.main`
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  height: 100%;

  ${mediaQueries.small} {
    overflow: scroll;
  }

  ${mediaQueries.small} {
    overflow: scroll;
    height: auto;
    /* padding-top: 86.41px; // taken from the height of the header */

    ${({ isMenuOpen }) =>
      isMenuOpen &&
      css`
        height: 0;
      `}}
  }
`;

const OverlayContainer = styled.div`
  overflow-y: hidden;

  &::before {
    background-color: black;
    position: fixed;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
  ${mediaQueries.small} {
    height: 100%;
  }
`;

const Layout = ({ children }) => {
  const { width } = useWindowSize();
  const isSmallScreen = width <= screenSizes.phone;

  const { isSubmissionError } = useContext(FormContext);

  const { isMenuOpen } = useContext(MenuContext);

  return (
    <StyledContainer isError={isSubmissionError}>
      <Header isMenuOpen={isMenuOpen} />
      {isMenuOpen && (
        <OverlayContainer>
          <Menu />
        </OverlayContainer>
      )}
      <StyledMain isMenuOpen={isMenuOpen}>{children}</StyledMain>
      {!isSmallScreen && <Sidebar />}
    </StyledContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
