import React, { useState } from "react";
import { graphql } from "gatsby";

import { useWindowSize } from "../../lib/useWindowSize";
import { screenSizes } from "../../styles/variables";
import SEO from "../../components/seo";
import {
  MixdownWrapperWeb,
  LeftWrapper,
  MixdownTitle,
  RightWrapper,
  Description,
  SmallText,
  ScrollWrapper,
  StemsPriceContainer,
  BaseText,
  ArrowImage,
  Inline,
} from "../../StyledComponents/mixdown.styles";
import { NextButton } from "../../components/NextButton";
import { BackButton } from "../../components/BackButton";
import Toggle from "react-toggle";
import MixdownCostsMobile from "../../components/mixdownMobilePages/CostsMobile";

export const productionMap = {
  false: {
    priceMultiplier: 2,
    title: "unplugged",
  },
  true: {
    priceMultiplier: 1,
    title: "electronic",
  },
};

const MixdownCosts = ({ data }) => {
  const [typeOfProduction, setTypeOfProduction] = useState(true);
  const { width, height } = useWindowSize();

  const isBelowTablet = width <= screenSizes.tablet;

  const isScrollable = height < 568;

  const {
    titleLeftCosts,
    costsDescription,
    titleRightCosts,
    explanation,
    stemPrices,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const handleSwitch = e => {
    setTypeOfProduction(!typeOfProduction);
  };

  return (
    <>
      <SEO title="SPANDAU20 Mixdown Costs" />
      {!isBelowTablet && isScrollable ? <ArrowImage /> : null}
      {!isBelowTablet ? (
        <>
          <MixdownWrapperWeb>
            <LeftWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <MixdownTitle>{titleLeftCosts}</MixdownTitle>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div style={{ textAlign: "right" }}>
                    <SmallText>Band / </SmallText>
                    <SmallText>unplugged</SmallText>
                  </div>
                  <Toggle
                    onChange={handleSwitch}
                    checked={typeOfProduction}
                    icons={false}
                  />
                  <div>
                    <SmallText>Electronic</SmallText>
                    <SmallText>production</SmallText>
                  </div>
                </div>
              </div>
              <SmallText>{costsDescription}</SmallText>
              <ScrollWrapper>
                <StemsPriceContainer
                  style={{ marginTop: "24px", marginBottom: "4px" }}
                >
                  <SmallText>STEMS</SmallText>
                  <SmallText>PRICE</SmallText>
                </StemsPriceContainer>
                {stemPrices.map(stemPrice => (
                  <StemsPriceContainer key={stemPrice.stems}>
                    <BaseText>{stemPrice.stems}</BaseText>
                    <BaseText>
                      {stemPrice.price *
                        productionMap[typeOfProduction].priceMultiplier}{" "}
                      €
                    </BaseText>
                  </StemsPriceContainer>
                ))}
              </ScrollWrapper>
            </LeftWrapper>
            <RightWrapper>
              <div>
                <MixdownTitle>{titleRightCosts}</MixdownTitle>
                <Description>{explanation}</Description>
              </div>
              <Inline justifyContent="space-between">
                <BackButton />
                <NextButton url={"/mixdown/order"}>Get a quote</NextButton>
              </Inline>
            </RightWrapper>
          </MixdownWrapperWeb>
        </>
      ) : (
        <MixdownCostsMobile data={data} />
      )}
    </>
  );
};

export default MixdownCosts;

export const query = graphql`
  query CostsQuery {
    allFile(filter: { sourceInstanceName: { eq: "mixdownCosts" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              titleLeftCosts
              costsDescription
              titleRightCosts
              explanation
              stemPrices {
                stems
                price
              }
            }
          }
        }
      }
    }
  }
`;
