import { navigate } from "gatsby";
import React, { useRef } from "react";
import styled from "styled-components";
import { mediaQueries, fontSizes } from "../styles/variables";

export const ButtonWrapper = styled.div`
  font-family: "neue-haas-grotesk-display";
  font-size: ${fontSizes.xBig}px;
  line-height: ${fontSizes.xBig}px;
  color: white;
  padding: 0;
  align-self: flex-end;

  ${mediaQueries.small} {
    font-size: ${fontSizes.xBig}px;
    line-height: ${fontSizes.large}px;
  }
  width: fit-content;
  position: relative;

  > Button {
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "black")};
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 300;
    text-decoration: none;
    height: min-content;
    text-transform: uppercase;

    &:nth-child(1) {
      padding-right: 20px;

      ${mediaQueries.small} {
        padding: 0;
      }
    }

    &:active {
      color: white;
    }
  }
`;

export const BackButton = ({ bgColor }) => {
  const prevRef = useRef(null);

  return (
    <ButtonWrapper bgColor={bgColor}>
      <button
        ref={prevRef}
        name="left"
        type="button"
        onClick={() => navigate(-1)}
        onMouseEnter={() => prevRef.current.classList.add("prev-hover")}
        onMouseLeave={() => prevRef.current.classList.remove("prev-hover")}
      >
        BACK
      </button>
    </ButtonWrapper>
  );
};
